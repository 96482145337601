import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { Account, Role } from '../../models/account.model';
import { UserSelectors } from '../../store/selectors/user.selectors';

/**
 * Example usage:
 * <div *roles="[Role.PREMIUM]"> </div> - will be visible to Premium users and Admins + Managers
 * <div *roles="[Role.PREMIUM]; selectedOnly: true"> </div> - will be visible to Premium users only
 * <div *roles="[]; loginRequired: false"> </div> - will be visible to logged out users only
 * <div *roles="[Role.PREMIUM]; loginRequired: false"> </div> - will be visible to Premium users and logged out users
 * <div *roles> </div> - will be visible to all users except logged out users.
 */
@Directive({
    standalone: true,
    selector: '[roles]'
})
export class RolesDirective implements OnInit {
    @Input() roles: Role[];

    @Input() set rolesSelectedOnly(boolean) {
        this._selectedOnly = boolean;
    }

    @Input() set rolesLoginRequired(boolean) {
        this._loginRequired = boolean;
    }

    private _selectedOnly: boolean = false;
    private _loginRequired: boolean = true;
    private directiveAlive: boolean = true;
    private account: Account;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userSelectors: UserSelectors,
        private cdr: ChangeDetectorRef
    ) {}

    private hasPermission(): boolean {
        if (!this.account && this._loginRequired) {
            return false;
        }

        if (!this.account && !this._loginRequired) {
            return true;
        }

        return this.account.roles.some(role => this.roles.includes(role));
    }

    private updateView() {
        if (this.hasPermission()) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }

        this.cdr.markForCheck();
    }

    ngOnInit(): void {
        // Apparently you can't set default values for inputs in a structural directive, so
        // you gotta do it in ngOnInit
        this.roles = this.roles || (Object.values(Role) as Role[]);

        if (!this._selectedOnly) {
            this.roles = Array.from(new Set([...this.roles, Role.MANAGER, Role.ADMIN]));
        }

        this.userSelectors.account$
            .pipe(takeWhile(() => this.directiveAlive))
            .subscribe(account => {
                this.account = account;
                this.updateView();
            });
    }

    ngOnDestroy() {
        this.directiveAlive = false;
    }
}

